/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, ContactForm, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"} description={"kontaktní informace a mapy"}>
        <Column className="pb--10 pt--20" name={"kontakty"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--43" content={"Kontaktní informace a mapy<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box ff--3 fs--24 pt--10" content={"plasanovi@gmail.com<br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box ff--3 fs--24 pt--10" content={"Vojta a Týna&nbsp; 731 446 632\n<br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box ff--3 fs--24 pt--10" content={"Jedlová 521<br>330 12 Horní Bříza<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--3 fs--16 w--400 mb--30" style={{"maxWidth":632}} content={"Chcete se nás na něco zeptat? Volejte na výše uvedené číslo a nebo nám klidně napište ;-)"}>
              </Text>

              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Jméno a příjmení","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Váš email","type":"email","required":true,"placeholder":"Zadejte Váš email"},{"name":"Vaše telefonní číslo","type":"text","required":true,"placeholder":"Zdejte svůj telefon +420"},{"name":"Vaše zpráva","type":"textarea","required":true,"placeholder":"Zde zanechte text své zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--40 pt--20" menu={true} name={"rvnhd1u514c"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box ff--3 fs--26 w--400 ls--001" content={"Svatba Plašanů<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"/"} target={"_blank"} content={"Svatba Plašanů úvod"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"/fotografie"} target={"_blank"} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"#kontakty"} target={"_blank"} content={"Místo svatby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"#kontakty"} target={"_blank"} content={"Zpět nahoru"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}